import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ErrorCallback, ResponseToken, ResponsetokenCallback } from '../../shared/Models/reponseMessage';
import jwt_decode from "jwt-decode";
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../shared/Services/account.service';

@Injectable({ providedIn: 'root' })
export class FlowAccountService {
	private userSubject: BehaviorSubject<User>;
	user$: Observable<User>;

	constructor(private http: HttpClient) {
		this.userSubject = new BehaviorSubject<User>(this.isAuthenticated() ? { Id: Number(localStorage.getItem('userId')), UserName: localStorage.getItem('userName') } : null);
		this.user$ = this.userSubject.asObservable();
	}
	login(email: string, password: string, callback: ResponsetokenCallback, errorback: ErrorCallback) {
		const data = "username=" + encodeURI(email) + "&password=" + encodeURI(password) + "&grant_type=password" + "&client_id=Workflow.WebApi";
		const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
		this.http.post<ResponseToken>('/connect/token', data, { headers: reqHeader }).subscribe(response => {
			const identity = jwt_decode(response.access_token);
			localStorage.setItem('userId', identity.Id);
			localStorage.setItem('userName', identity.Name);
			localStorage.setItem('email', identity.Email);
			localStorage.setItem('lastLogin', Date.now().toString());
			localStorage.setItem('lastLogin', Date.now().toString());
			localStorage.setItem('userToken', response.access_token);
			localStorage.setItem('refreshToken', response.refresh_token);
			localStorage.setItem('userRoles', JSON.stringify(identity.role));
			localStorage.setItem('expires_in', response.expires_in.toString());
			const expirationDate = new Date();
			expirationDate.setSeconds(expirationDate.getSeconds() + response.expires_in);

			localStorage.setItem('tokenExpiration', expirationDate.toString());
			this.userSubject.next({ Id: identity.Id, UserName: identity.Name });
			callback(response);
		}, error => { errorback(error) });
	}
	logout() {
		localStorage.removeItem('userId');
		localStorage.removeItem('userName');
		localStorage.removeItem('email');
		localStorage.removeItem('userToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem('expires_in');
		localStorage.removeItem('tokenExpiration');
		if (this.userSubject) {
			this.userSubject.next(null);
		}
	}
	isAuthenticated(): boolean {
		if (localStorage.getItem('userToken') === null && localStorage.getItem('tokenExpiration') === null) {
			return false;
		}

		const tokenExpiration = new Date(localStorage.getItem('tokenExpiration'));
		if (tokenExpiration < new Date()) {
			this.logout();
			return false;
		}

		return true;
		//this.http.get('/api/Account/IsAuthenticated').subscribe(response => callback(response as ResponseResult<boolean>), error => console.log(AccountService.name, error))
	}
}