import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseCallback, ResponseMessage } from '@shared/Models/reponseMessage';

@Injectable()
export class FlowTaskService {
	constructor(private http: HttpClient) {
	}
	getWorkflowTaks(callback: ResponseCallback) {
		//return Promise.resolve(TASKS);
		this.http.get('/api/flowtask').subscribe(response => callback(response as ResponseMessage), error => console.log(FlowTaskService.name, error));
	}

	getWorkflowTakByName(taskName: string, callback: ResponseCallback) {
		//return Promise.resolve(TASKS);
		this.http.get(`/api/flowtask/${taskName}`).subscribe(response => callback(response as ResponseMessage), error => console.log(FlowTaskService.name, error));
	}
}