import { WorkflowTask } from './WorkflowTask/workflow-task.model';

export const TASKS: WorkflowTask[] = [
	//{ name: 'Start', title: 'Start', icon: 'help', type: 'StartTask', bgColor: '#FF2222', displayInfo: { x: 0, y: 0 } },
	//{ name: 'End', title: 'Start', icon: 'edit', type: 'EndTask', bgColor: '#11ee22', displayInfo: { x: 0, y: 0 }},
	////{ name: 'Between', icon: 'info', type: 'draw2d.shape.node.Between', bgColor: '#444444' },
	//{ name: 'BrowseFile', title: 'Start', icon: 'info', type: 'BrowseFile', bgColor: '#444444', displayInfo: { x: 0, y: 0 } },
	////{ name: 'MakeBlastDb', icon: 'info', type: 'MakeBlastDb',bgColor: '#444444' },
	////{ name: 'Blastn', icon: 'info', type: 'Blastn', bgColor: '#444444' },
	////{ name: 'Diamond', icon: 'info', type: 'draw2d.shape.basic.Diamond', bgColor: '#444444' },
	//{ name: 'Label', title: 'Start', icon: 'info', type: 'draw2d.shape.basic.Label', bgColor: '#444444', displayInfo: { x: 0, y: 0 } },
];