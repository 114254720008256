import { NgxMatDateAdapter, NgxMatNativeDateAdapter, NGX_MAT_DATE_FORMATS, NGX_MAT_NATIVE_DATE_FORMATS } from "@angular-material-components/datetime-picker";
import { Platform, PlatformModule } from "@angular/cdk/platform";
import { Inject, Injectable, NgModule, Optional } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DateFormat } from "./Models/Enums";
import { WebsiteGlobalSettingsService } from "./Services/website-globalsettings-service";

export enum InputDateType {
	Year,
	YearAndMonth,
	Full
}

@Injectable()
export class CustomDateAdapter extends NgxMatNativeDateAdapter {
	private _dateFormat: DateFormat = DateFormat.Universal;
	dateIncludeTime: boolean = false;

	includeTime: boolean = false;

	public InputDateType : InputDateType = InputDateType.Full;

	constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string, platform: Platform, settings: WebsiteGlobalSettingsService) {
		super(matDateLocale, platform);
		this._dateFormat = settings.websiteSettings.DateFormat;
	} 
	
    format(date: Date, displayFormat: any): string {
		if(!date) {
			return;
		}
		// 	this._inputDateType = InputDateType.Full;
		// }

		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		let timeValue = "";

		if(this.includeTime) {
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();
			timeValue = " " + this._to2digit(hours) + ':' + this._to2digit(minutes) + ':' + this._to2digit(seconds);
		}
		
		switch(this._dateFormat) {
			case DateFormat.Europe:
				switch(this.InputDateType) {
					case InputDateType.Year :
						return year.toString(); //dd/MM/yyyy
					case InputDateType.YearAndMonth :
						return this._to2digit(month) + '/' + year; //dd/MM/yyyy
					default:
						return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year + timeValue; //dd/MM/yyyy
				}
			case DateFormat.US:
				switch(this.InputDateType) {
					case InputDateType.Year :
						return  year.toString(); //MM/dd/yyyy
					case InputDateType.YearAndMonth :
						return  this._to2digit(month) + '/' + year; //MM/dd/yyyy
					default:
						return  this._to2digit(month) + '/' + this._to2digit(day) + '/' + year + timeValue; //MM/dd/yyyy
				}
			case DateFormat.Universal:
				switch(this.InputDateType) {
					case InputDateType.Year :
						return year.toString(); //yyy/MM/dd
					case InputDateType.YearAndMonth :
						return year + '/' + this._to2digit(month); //yyy/MM/dd
					default:
						return year + '/' + this._to2digit(month) + '/' + this._to2digit(day) + timeValue; //yyy/MM/dd
				}
				
		}
	}
	
	parse(value: string): any {
		if(value == null || value.trim() == "") {
			return null;
		}
		if(!this.validate(value)) {
			return "Invalid";
		}
		let dateValue = null;
		let timeValue = null;
		if(this.includeTime || this.dateIncludeTime) {
			let values = value.split(" ");
			dateValue = values[0];
			timeValue = values[1];
		}
		else {
			dateValue = value;
		}
		
		let dateParms = dateValue.split(/[\/\-]/);
		let timeParms = this.includeTime ? (this.dateIncludeTime? timeValue.split(":") : [0,0,0]) : null;
		if(dateParms.length == 1) {
			this.InputDateType = InputDateType.Year;
		}
		else if (dateParms.length == 2) {
			this.InputDateType = InputDateType.YearAndMonth;
		}
		else {
			this.InputDateType = InputDateType.Full;
		}

		let date: Date;
		switch(this._dateFormat) {
			case DateFormat.Europe:
				if(this.includeTime) {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1, Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[1]), Number(dateParms[0]) - 1, 1, Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						default:
							date = new Date(Number(dateParms[2]), Number(dateParms[1]) - 1, Number(dateParms[0]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
					}
					//date = new Date(Number(dateParms[2]), Number(dateParms[1]) - 1, Number(dateParms[0]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
				}
				else {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1);
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[1]), Number(dateParms[0]) - 1, 1);
							break;
						default:
							date = new Date(Number(dateParms[2]), Number(dateParms[1]) - 1, Number(dateParms[0]));
							break;
					}
				}

				break;
			case DateFormat.US:
				if(this.includeTime) {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1, Number(dateParms[1]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[1]), Number(dateParms[0]) - 1, 1, Number(dateParms[1]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						default:
							date = new Date(Number(dateParms[2]), Number(dateParms[0]) - 1, Number(dateParms[1]), Number(dateParms[1]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
					}
					//date = new Date(Number(dateParms[2]), Number(dateParms[0]) - 1, Number(dateParms[1]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
				}
				else {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1);
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[1]), Number(dateParms[0]) - 1, 1);
							break;
						default:
							date = new Date(Number(dateParms[2]), Number(dateParms[0]) - 1, Number(dateParms[1]));
							break;
					}
				}
				break;
			case DateFormat.Universal:
				if(this.includeTime) {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1, Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[0]), Number(dateParms[1]) - 1, 1, Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
						default:
							date = new Date(Number(dateParms[0]), Number(dateParms[1]) - 1, Number(dateParms[2]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
							break;
					}
					//date = new Date(Number(dateParms[0]), Number(dateParms[1]) - 1, Number(dateParms[2]), Number(timeParms[0]), Number(timeParms[1]), Number(timeParms[2]));
				}
				else {
					switch(this.InputDateType) {
						case InputDateType.Year :
							date = new Date(Number(dateParms[0]), 0, 1);
							break;
						case InputDateType.YearAndMonth :
							date = new Date(Number(dateParms[0]), Number(dateParms[1]) - 1, 1);
							break;
						default:
							date = new Date(Number(dateParms[0]), Number(dateParms[1]) - 1, Number(dateParms[2]));
							break;
					}
				}
				break;
		}

		return date;
	}

	clone(date: Date): Date {
		//this.InputDateType = InputDateType.Full;
		return super.clone(date);
	}

	public isValid(value: any): boolean {
		return value != "Invalid";
	}
 
    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
	}
	
	public validate(value: string): boolean {
		switch(this._dateFormat) {
			case DateFormat.Europe:
				return (this.dateIncludeTime = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4} (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}|(0?[1-9]|1[012])[\/\-]\d{4}|\d{4}$/.exec(value)?.some(x=> x== value);
			case DateFormat.US:
				return (this.dateIncludeTime = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4} (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}|(0?[1-9]|1[012])[\/\-]\d{4}|\d{4}$/.exec(value)?.some(x=> x== value);
			case DateFormat.Universal:
				return (this.dateIncludeTime = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01]) (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]$|[12][0-9]|3[01])|\d{4}[\/\-](0?[1-9]|1[012])|\d{4}$/.exec(value)?.some(x=> x== value);
		}
		
	}

	static validateDate(value:string, format: DateFormat) {
		switch(format) {
			case DateFormat.Europe:
				return (/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4} (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}|(0?[1-9]|1[012])[\/\-]\d{4}|\d{4}$/.exec(value)?.some(x=> x== value);
			case DateFormat.US:
				return (/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4} (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}|(0?[1-9]|1[012])[\/\-]\d{4}|\d{4}$/.exec(value)?.some(x=> x== value);
			case DateFormat.Universal:
				return (/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01]) (?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.exec(value)?.some(x=> x== value)) || /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]$|[12][0-9]|3[01])|\d{4}[\/\-](0?[1-9]|1[012])|\d{4}$/.exec(value)?.some(x=> x== value);
		}
	}
}


@NgModule({
    imports: [PlatformModule],
    providers: [
        { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
    ],
})
export class CustomNativeDateModule { }

@NgModule({
    imports: [CustomNativeDateModule],
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_NATIVE_DATE_FORMATS }],
})
export class CustomMatNativeDateModule { }