import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseCallback, ResponseMessage, ResponseResult } from '@shared/Models/reponseMessage';
import { WebsiteGeneralOptions } from '@shared/Models/WebsiteGeneralOptions';
import { ThemeElement } from '../Models/ThemeElement';
import { displayError, endAppSpinner } from 'core/extensions';

const serviceUnavailable: string = '503 Service Unavailable';
const serviceUnavailableDescription: string = 'The website is currently down for maintenance.';
const serviceUnavailableInfo: string = 'We expect to be back soon, Thanks for your patience';
const unexpectedError: string = 'An unexpected error has occurred';
const unexpectedErrorDescription: string = 'The website failed to initialize because of an unknow error, we are sorry for the inconvenience.';

@Injectable({
	providedIn: 'root',
})
export class WebsiteGlobalSettingsService {
	websiteSettings: WebsiteGeneralOptions;
	activeTheme: ThemeElement;

	constructor(private http: HttpClient/*, private gtag: Gtag*/) { }

	init() {
		Promise.all([this.fetchSettings(), this.fetchTheme()]);
	}

	fetchSettings() {
		let promise = new Promise((resolve, reject) => {
			this.http.get<ResponseResult<WebsiteGeneralOptions>>('/api/Settings/GetWebsiteGeneralOptions')
				.toPromise()
				.then(
					response => { // Success
						if (response.Success) {
							const settings = response.Data;
							document.title = settings.WebsiteName;
							document.getElementById('appFavicon').setAttribute('href', settings.FavIconUrl ? settings.FavIconUrl : '/favicon.ico');
							this.websiteSettings = settings;
							if (this.websiteSettings.GoogleAnalyticsOptions && this.websiteSettings.GoogleAnalyticsOptions.EnableGoogleAnalytics) {
								//Load google analytics script.
								const script = document.createElement('script');
								script.async = true;
								script.src = "https://www.googletagmanager.com/gtag/js?id=" + this.websiteSettings.GoogleAnalyticsOptions.GoogleAnalyticsKey;
								document.getElementsByTagName('head')[0].appendChild(script);
							}
							resolve();
						}
						else {
							endAppSpinner();
							displayError(unexpectedError, unexpectedErrorDescription);
							reject();
						}
					},
					(reason :HttpErrorResponse) => { // Error
						endAppSpinner();
						if(reason.status == 0) {
							displayError(serviceUnavailable, serviceUnavailableDescription, serviceUnavailableInfo);
						}
						else {
							displayError(unexpectedError, unexpectedErrorDescription);
						}
					  reject();
					}
				);
		  });
		  return promise;
	}

	fetchTheme() {
		return this.http.get<ResponseMessage>('/api/Theme/GetActiveTheme')
			.toPromise()
			.then((response: ResponseMessage) => {
				if (response.Success) {
					this.activeTheme = response.Data;
				}
			});
	}

	saveWebsiteGeneralOptions(settings: WebsiteGeneralOptions, callback: ResponseCallback) {
		this.http.post('/api/Settings/SaveWebsiteGeneralOptions', settings).subscribe(response => callback(response as ResponseMessage), error => console.log(WebsiteGlobalSettingsService.name, error));
	}

	recycle(callback: ResponseCallback) {
		this.http.post('/api/Settings/Recycle', null).subscribe(response => callback(response as ResponseMessage), error => console.log(WebsiteGlobalSettingsService.name, error));
	}
}