export enum EFieldType {
	Text,
	HTML,
	XML,
	CSS

}
export enum PasswordType {
	SimplePassword,
	WithConfirmPassword,
	LinkToChangePassword
}
export enum TextType {
	Email,
	Password,
	VatNumber,
	Country,
	TextArea,
	FreeText,
	TextWithMask,
	TextWithPattern
}
export const TextTypeLabels = new Map<TextType, string>([
	[TextType.Email, 'Email (!)'],
	[TextType.Password, 'Password (!)'],
	[TextType.VatNumber, 'Vat number (!)'],
	[TextType.Country, 'Country (!)'],
	[TextType.TextArea, 'Text area'],
	[TextType.FreeText, 'Free text'],
	[TextType.TextWithMask, 'Text with mask (!)'],
	[TextType.TextWithPattern, 'Text with pattern (!)']
]);
export enum MaskType {
	Date,
	DateAndHour,
	Hour,
	Phone,
	Custom
}
export enum AllFieldsType {
	//Biolomics fields    
	AField = 1,
	CField = 3,
	DField = 4,
	EField = 5,
	FField = 6,
	GField = 7,
	HField = 8,
	IField = 9,
	KField = 11,
	LField = 12,
	MField = 13,
	NField = 14,
	OField = 15,
	SField = 19,
	TField = 20,
	UField = 21,
	VField = 22,
	WField = 23,
	YField = 25,
	Syn = 27,
	//new link fields
	ALink = 70,
	CLink = 72,
	DLink = 73,
	ELink = 74,
	HLink = 77,
	ILink = 78,
	KLink = 80,
	MLink = 82,
	SLink = 88,
	TLink = 90,
	VLink = 92,

	FLinkField = 106,
	OLinkField = 108,
	SynLink = 109,
	LLinkField = 112,
	NLinkField = 114,
	WLinkField = 115,
	RLinkField = 118,

	//static fields
	TextField = 200,
	CheckBoxField = 201,
	DropDownField = 202,
	TextAreaField = 203,
	DateTimeField = 204,
	NumberField = 205,
	RadioGroupField = 206,
	CaptchaField = 207,
	Separator = 208,
	StaticText = 209,
	Link = 210,
	StaticHtml = 211,
	//recordname
	RecordName = -100,
	RecordId = -101,
	AnyTextField = -102,
	CreationDate = -103,
	LastChangeDate = -104,
	Owner = -105,
	LastChangeUser = -106,
	// container
	SectionContainer = -1,
	TabContainer = -2,
	TableContainer = -3,
	TileContainer = -4,
	LayoutContainer = -5,
	ColumnContainer = -6,
	StepperContainer = -7,
	ExpansionPanelContainer = -8,
	CarouselContainer = -9,
}
export const TargetFieldType = new Map<AllFieldsType, AllFieldsType>([
	[AllFieldsType.ALink, AllFieldsType.AField],
	[AllFieldsType.CLink, AllFieldsType.CField],
	[AllFieldsType.DLink, AllFieldsType.DField],
	[AllFieldsType.ELink, AllFieldsType.EField],
	[AllFieldsType.FLinkField, AllFieldsType.FField],
	[AllFieldsType.HLink, AllFieldsType.HField],
	[AllFieldsType.ILink, AllFieldsType.IField],
	[AllFieldsType.KLink, AllFieldsType.KField],
	[AllFieldsType.LLinkField, AllFieldsType.LField],
	[AllFieldsType.MLink, AllFieldsType.MField],
	[AllFieldsType.NLinkField, AllFieldsType.NField],
	[AllFieldsType.OLinkField, AllFieldsType.OField],
	[AllFieldsType.SynLink, AllFieldsType.Syn],
	[AllFieldsType.SLink, AllFieldsType.SField],
	[AllFieldsType.TLink, AllFieldsType.TField],
	[AllFieldsType.VLink, AllFieldsType.VField],
	[AllFieldsType.WLinkField, AllFieldsType.WField],
]);
export enum QueryOperation {
	Unknown = 0,

	//	*****	Subfield combo box	*****
	ValueIs = 1000,
	ValueIsNot = 1001,
	ValueIsUndefined = 1002,
	ValueIsDuplicated = 1003, // warning: only records with ID >= 0 will work properly in this case

	//	for text fields
	TextStartWith = 1100,
	TextContains = 1101,
	TextExactMatch = 1102,

	//	for dates
	DateIsBefore = 1200,
	DateIsAfter = 1201,
	DateIsInTheRange = 1202,
	DateIsUndefined = 1203, // use ValueIsUndefined
	DateIsEqualTo = 1204,

	DateYearIs = 1205,
	DateMonthIs = 1206,
	DateDayOfMonthIs = 1207,

	//	for numbers and other fields
	NbrIsGreaterThan = 1300,
	NbrIsGreatThanOrEqualTo = 1301,
	NbrIsEqualTo = 1302,
	NbrIsSmallerThan = 1303,
	NbrIsSmallerThanOrEqualTo = 1304,
	NbrIsDifferentFrom = 1305,
	NbrIsInList = 1306,

	//	O Field
	ValueIsRoot = 1310,

	// OField and their child records
	NameStartWithAndChild = 1311,
	NameContainsAndChild = 1312,
	NameExactMatchAndChild = 1313,

	//	K Field
	ValueIsNearlyIdentical = 1320,
	ValueIsVerySimilar = 1321,
	ValueIsSimilar = 1322,

	//	S Field
	SFieldSmallerThanMin = 1400,
	SFieldSmallerThanLP = 1401,
	SFieldSmallerThanHP = 1402,
	SFieldSmallerThanMax = 1403,
	SFieldGreaterThanMin = 1404,
	SFieldGreaterThanLP = 1405,
	SFieldGreaterThanHP = 1406,
	SFieldGreaterThanMax = 1407,
	SFieldBetweenLPAndHP = 1408,
	SFieldBetweenMinAndMax = 1409,

	//	W Field, search on the name or on the values
	NameStartWith = 1499, // also used by the TTaxoField
	NameContains = 1500,  // also used by the TTaxoField
	NameExactMatch = 1501,
	NameIsUndefined = 1502,
	NameIsDuplicated = 1503, // search the name, as ValueIsDuplicated exists too for W fields !

	//NbrIsGreaterThanY = 1504   '	W comparison
	//NbrIsGreatThanOrEqualToY = 1505
	//NbrIsEqualToY = 1506
	//NbrIsSmallerThanY = 1507
	//NbrIsSmallerThanOrEqualToY = 1508
	//NbrIsGreaterThanX = 1509   '	X Comparison
	//NbrIsGreatThanOrEqualToX = 1510
	//NbrIsEqualToX = 1511
	//NbrIsSmallerThanX = 1512
	//NbrIsSmallerThanOrEqualToX = 1513

	//	Record Link
	TargetRecordNameStartWith = 1520,
	TargetRecordNameContains = 1521,
	TargetRecordNameExactMatch = 1522,
	TargetRecordNameIsUndefined = 1523,

	TargetRecordAnyTextStartWith = 1524,
	TargetRecordAnyTextContains = 1525,
	TargetRecordAnyTextExactMatch = 1526,
	TargetRecordAnyTextIsUndefined = 1527,

	LinkContains = 1528,
	LinkIsEmpty = 1529,
	LinkIsDuplicated = 1530, // warning: only records with ID >= 0 will work properly in this case

	//	rights on the record
	GroupCan = 1540,
	GroupCannot = 1541,

	//	locked/unlocked
	Locked = 1545,
	Unlocked = 1546,

	//	Is Online = Used for identification?
	Used = 1548,
	Unused = 1579,

	//	Annotations
	Exist = 1600,
	DoesNotExist = 1601,

	//	Taxo and Taxo links
	NameStartWithAndSynonyms = 1620,
	NameContainsAndSynonyms = 1621,
	NameExactMatchAndSynonyms = 1622,

	//	Owner and LastChangeUser
	UserNameStartWith = 1631,
	UserNameContains = 1632,
	UserNameExactMatch = 1633,
	UserPropertyStartWith = 1634,
	UserPropertyContains = 1635,
	UserPropertyExactMatch = 1636,
	UserContains = 1637, // open the search target record dialog
	CurrentUser = 1638,
	//	History
	UserNameOrEmailStartWith = 1701,
	UserNameOrEmailContains = 1702,
	UserNameOrEmailExactMatch = 1703
}
export enum StaticFieldsType {
	TextField,
	CheckBoxField,
	DropDownField,
	TextAreaField,
	DateTimeField
}

export enum FileType {
	XLS = 1,
	XLSX = 1 << 1,
	DOC = 1 << 2,
	DOCX = 1 << 3,
	PPT = 1 << 4,
	PTX = 1 << 5,
	XML = 1 << 6,
	Images = 1 << 7,
	PDF = 1 << 8,
	OtherFiles = 1 << 9,
}
export const FileTypeLabels = new Map<FileType, string>([
	[FileType.XLS, 'XLS (*.xls)'],
	[FileType.XLSX, 'XLSX (*.xlsx)'],
	[FileType.DOC, 'DOC (*.doc)'],
	[FileType.DOCX, 'DOCX (*.docx)'],
	[FileType.PPT, 'PPT (*.ppt)'],
	[FileType.PTX, 'PTX (*.pptx)'],
	[FileType.XML, 'XML (*.xml)'],
	[FileType.Images, 'Images (*.jpeg,*.jpg,*.bmp,*.png)'],
	[FileType.PDF, 'PDF (*.pdf)'],
	[FileType.OtherFiles, 'Other files (*.*)']
]);

export enum ProcessSourceType {
	Table,
	Layout
}
//export enum DesignMode {
//	Deposit,
//	Display,
//	Query,
//	Summary
//}

export enum DesignMode {
	Display = 1,
	Deposit = 2,
	Query = 3,
	Summary = 4,
	Scenario = 5,
	//Cart = 6,
	Menu = 6,
	DefaultQuery = 7,
	Alignment = 8,
	UserRegistration = 9,
	WebServiceView = 10,
	Workflow = 11
}

export const DesignModeLabels = new Map<DesignMode, string>([
	[DesignMode.Display, 'Display'],
	[DesignMode.Deposit, 'Form'],
	[DesignMode.Query, 'Query'],
	[DesignMode.Summary, 'Summary'],
	[DesignMode.Scenario, 'Scenario'],
	[DesignMode.Menu, 'Menu'],
	[DesignMode.DefaultQuery, 'Default query'],
	[DesignMode.Alignment, 'Alignment'],
	[DesignMode.UserRegistration, 'User registration'],
	[DesignMode.WebServiceView, 'WebService view'],
]);

export enum LogicOperation {
	None,
	Not,
	And,
	Or,
	AndNot,
	OrNot
}

export const LogicOperationLabels = new Map<LogicOperation, string>([
	[LogicOperation.None, ''],
	[LogicOperation.Not, 'Not'],
	[LogicOperation.And, 'And'],
	[LogicOperation.Or, 'Or'],
	[LogicOperation.AndNot, 'And Not'],
	[LogicOperation.OrNot, 'Or Not']
]);

export enum SearchMode {
	Simple,
	Advance
}

export enum PageElementType {
	Html,
	AdvancedQuery,
	SimpleQuery,
	SummaryGrid,
	DepositForm,
	DetailPage,
	StepperContainer,
	TabContainer,
	LayoutContainer,
	ExpansionPanel,
	PlaceHolder,
	HeaderContainer,
	FooterContainer,
	SidebarContainer,
	PageMenu,
	FastSearch,
	SectionContainer,
	Alignment,
	Scenario,
	Contact,
	CarouselContainer,
	TopNews,
	ErrorPlaceholder,
	Dashboard
}
export enum DisplayPosition {
	Right,
	Left
}

export enum ButtonsDisplay {
	Text,
	Icon
}

export enum WebServiceOutputFormat {
	JSON,
	XML
}

export enum ColumnCount {
	One = 1,
	Two = 2,
	Three = 3,
	Four = 4,
	Six = 6,
	Twelve = 12,
}
export const ColumnCountLabels = new Map<ColumnCount, string>([
	[ColumnCount.One, '1'],
	[ColumnCount.Two, '2'],
	[ColumnCount.Three, '3'],
	[ColumnCount.Four, '4'],
	[ColumnCount.Six, '6'],
	[ColumnCount.Twelve, '12']
]);
export enum RowCount {
	One = 1,
	Two = 2,
	Three = 3,
	Four = 4,
	Five = 5,
	Six = 6,
	Seven = 7,
	Eight = 8,
	Nine = 9,
	Ten = 10

}
export const RowCountLabels = new Map<RowCount, string>([
	[RowCount.One, '1'],
	[RowCount.Two, '2'],
	[RowCount.Three, '3'],
	[RowCount.Four, '4'],
	[RowCount.Five, '5'],
	[RowCount.Six, '6'],
	[RowCount.Seven, '7'],
	[RowCount.Eight, '8'],
	[RowCount.Nine, '9'],
	[RowCount.Ten, '10']
]);
export enum Languages {
	Eng
}
export enum DateFormat {
	Europe,
	US,
	Universal
}
export const DateFormatLabels = new Map<DateFormat, string>([
	//	XFieldType.FieldTypeEnum.C
	[DateFormat.Europe, 'Europe : (dd/mm/yyyy)'],
	[DateFormat.US, 'US : (mm/dd/yyyy)'],
	[DateFormat.Universal, 'Universal : (yyyy/mm/dd)'],
]);
export enum SidebarMode {
	Over = 'over',
	Side = 'side',
	Push = 'push'
}
export enum OrientationMode {
	Horizontal = 0,
	Vertical = 1
}
export enum MenuType {
	Contact,
	News,
	Forum,
	Support,
	Admin,
	Alignement,
	Identification,
	Home,
	Custom,
	User,
	UserPage,
	SubUser,
	Separator
}

export enum QuerySubFieldCategory {
	Unknown = 0,

	//	*****	Subfield combo box	*****
	//	A Fields
	ASubfield = 1000,

	//	C Field
	CSubfield = 1001,

	//	M Field
	MSubfield = 1002,

	//	W Field
	WFieldName = 1005, // Name or Value?
	WFieldValue = 1006,
	WFieldPeaks = 1007,

	//	for Rights on the record, we display all available user groups
	Groups = 1008,

	// aa - Taxonomy - Begin
	SynRecord = 1010,
	SynNameField = 1011,
	SynAssociatedMorph = 1012,
	SynFacultativeSynonym = 1013,
	SynLocationField = 1014,
	SynObligateSynonym = 1015,
	SynClassification = 1016,
	SynAllSynonyms = 1017,
	SynDescription = 1018,
	SynStatus = 1019,

	SynFile = 1020
	// aa - Taxonomy - End
}

export enum ErrorType {
	Client,
	Server
}

export enum HttpStatusCode {
	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestUriTooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	UpgradeRequired = 426,
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HttpVersionNotSupported = 505
}

export const HttpStatusCodeLabels = new Map<HttpStatusCode, string>([
	[HttpStatusCode.BadRequest, 'Bad request'],
	[HttpStatusCode.Unauthorized, 'Unauthorized'],
	[HttpStatusCode.PaymentRequired, 'Payment required'],
	[HttpStatusCode.Forbidden, 'Forbidden'],
	[HttpStatusCode.NotFound, 'Not found'],
	[HttpStatusCode.MethodNotAllowed, 'Method not allowed'],
	[HttpStatusCode.NotAcceptable, 'Not acceptable'],
	[HttpStatusCode.ProxyAuthenticationRequired, 'Proxy authentication required'],
	[HttpStatusCode.RequestTimeout, 'Request timeout'],
	[HttpStatusCode.Conflict, 'Conflict'],
	[HttpStatusCode.Gone, 'Gone'],
	[HttpStatusCode.LengthRequired, 'Length required'],
	[HttpStatusCode.PreconditionFailed, 'Precondition failed'],
	[HttpStatusCode.RequestEntityTooLarge, 'Request entity too large'],
	[HttpStatusCode.RequestUriTooLong, 'Request Uri too long'],
	[HttpStatusCode.UnsupportedMediaType, 'Unsupported media type'],
	[HttpStatusCode.RequestedRangeNotSatisfiable, 'Requested range not satisfiable'],
	[HttpStatusCode.ExpectationFailed, 'Expectation failed'],
	[HttpStatusCode.UpgradeRequired, 'Upgrade required'],
	[HttpStatusCode.InternalServerError, 'Internal server error'],
	[HttpStatusCode.NotImplemented, 'Not implemented'],
	[HttpStatusCode.BadGateway, 'Bad gateway'],
	[HttpStatusCode.ServiceUnavailable, 'Service unavailable'],
	[HttpStatusCode.GatewayTimeout, 'Gateway timeout'],
	[HttpStatusCode.HttpVersionNotSupported, 'Http version not supported'],
]);

export enum DashboardWorkingMode {
	ViewerOnly,
	Designer
}

export const DashboardWorkingModeLabels = new Map<DashboardWorkingMode, string>([
	[DashboardWorkingMode.ViewerOnly, 'Viewer only'],
	[DashboardWorkingMode.Designer, 'Designer']
]);

export enum SearchTermMinLength {
	One = 1,
	Two = 2,
	Three = 3
}
export enum NameOrSummaryEnum {
	Name,
	Summary
}
export enum SearchOperation {
	StartsWith,
	Contains
}
export const SearchOperationLabels = new Map<SearchOperation, string>([
	[SearchOperation.StartsWith, 'Starts with'],
	[SearchOperation.Contains, 'Contains']
]);