import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class StyleSheetService {
	constructor(@Inject(DOCUMENT) private document: Document) { }

	load(name: string) {
		if(!this.document.head.querySelector("link[href='"+ name +".css']"))
		{
			const styleLink = this.document.createElement('link');
			styleLink.rel = 'stylesheet';
			styleLink.href = name + '.css';
			this.document.head.appendChild(styleLink);
		}
	}
}