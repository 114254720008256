import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injector } from '@angular/core';
import { WorkflowAppRouting } from './workflow-app.routing';
import { WorkflowAppComponent } from './workflow-app.component';
import { ConfigService } from '../config/config.service';
import { WebsiteGlobalSettingsService } from '../shared/Services/website-globalsettings-service';
import { RequestInterceptorModule } from '../core/interceptor/request-interceptor.module';
import { RequestNotifierService } from '../shared/Services/request-notifier.service';
import { ErrorNotifierService } from '../shared/Services/ErrorManagement/error-notifier.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { APP_BASE_HREF } from '@angular/common';
import { WorkflowService, FlowTaskService } from './services';
import { PreviousRouteService } from '../main/Services/previous-route.service';
import { StyleSheetService } from '../shared/Services/stylesheet.service';
import { CustomMatNativeDateModule } from '../shared/date.module';
import { FileService } from './services/file.service';
import { getSaver, SAVER } from './providers/file-saver.provider';
import { FlowAccountService } from './services/flow-account.service';
//import { DynamicScriptLoaderService } from '../shared/Services/dynamicScriptLoader.service';
//import { DockModal } from '../tools/DockModal/dock-modal';
//import { HttpClientModule } from '@angular/common/http';
export let InjectorInstance: Injector;
//import { BreadcrumbModule } from 'xng-breadcrumb';

@NgModule({
	imports: [
		WorkflowAppRouting,	
		//BreadcrumbModule,
		BrowserModule,
		BrowserAnimationsModule,
		//HttpClientModule,
		RouterModule,
		RequestInterceptorModule,
		ToastrModule.forRoot({ progressBar: true }),
		//NgxMatNativeDateModule,
		CustomMatNativeDateModule
	],

	declarations: [
		WorkflowAppComponent,	
	],
	entryComponents: [
	
	],
	providers: [	
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [ConfigService, WebsiteGlobalSettingsService],
			useFactory: (appConfigService: ConfigService, webSiteConfigService: WebsiteGlobalSettingsService) => {
				return () => {
					return appConfigService.loadConfig().then(() => webSiteConfigService.fetchSettings());
				};
			}
		},
		{
			provide: SAVER,
			useFactory: getSaver
		},
		ConfigService,
		WebsiteGlobalSettingsService,
		RequestNotifierService,
		ErrorNotifierService,
		{ provide: APP_BASE_HREF, useValue: '/' },
		WorkflowService,
		FlowTaskService,
		FileService,
		ToastrService,
		StyleSheetService,
		PreviousRouteService,
		FlowAccountService,
		//DynamicScriptLoaderService,
		//DockModal,
	],
	bootstrap: [WorkflowAppComponent]
})
export class WorkflowAppModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}


