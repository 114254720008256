import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/observable';
import { tap } from 'rxjs/operators/tap';
import { ConfigService } from '../../config/config.service';
import { RequestNotifierService } from '../../shared/Services/request-notifier.service';
import { ErrorNotifierService } from '../../shared/Services/ErrorManagement/error-notifier.service';
import { HttpStatusCode } from '@shared/Models/Enums';
import { Router } from '@angular/router';
   
@Injectable({
	providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
	 protected baseUrl :string;
	constructor(private notifier: RequestNotifierService, private errorNotifier: ErrorNotifierService, private configService:ConfigService, private router: Router) {
		//ConfigService.config.subscribe({
		//	next: (data:Config) => {
		//		this.baseUrl = data.baseUrl;
		//	}
		//});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {		
		const requesttUrl = req.url;
		//if (requesttUrl.toLowerCase().includes('/api/alignment/')) {
		//	req = req.clone({
		//		url: requesttUrl,
		//		headers: req.headers.set("WebsiteId", "88")
		//	});
		//}
		if (requesttUrl.toLowerCase().startsWith('/api/') || requesttUrl.toLowerCase().startsWith('/connect/token')) {
			const config = this.configService.getConfig();
			const fullRequesttUrl = config.baseUrl + requesttUrl;
			req = req.clone({
				url: fullRequesttUrl,
				headers: req.headers.set("WebsiteId", config.websiteId.toString()),
				withCredentials: true
			});
		}
		const userToken = localStorage.getItem('userToken');

		if (userToken != null && !requesttUrl.toLowerCase().includes('/api/alignment/')) {
			req = req.clone({
				headers: req.headers.set("Authorization", "Bearer " + userToken),
				withCredentials: true
			});
		}

		return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				this.notifier.notify(requesttUrl);
			}
		},
			(err: HttpErrorResponse) => {
				//this.notifier.notify(requesttUrl);
				if (!requesttUrl.toLowerCase().startsWith('/api/') && !requesttUrl.toLowerCase().startsWith('/connect/token')) {
					return;
				}
				if(err.status == HttpStatusCode.Forbidden && err.error.Reason == 'WeakPassword') {
					this.router.navigate(['/account/changepassword']);
					console.log(err);
					return;
				}
				//if (requesttUrl == 'http://services.bio-aware.com/Error/ReportError') {
				//	return;
				//}
				this.errorNotifier.notifyServerError(err);
			}));
	}
}