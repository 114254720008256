import { Routes, DefaultUrlSerializer, UrlTree, RouterModule } from '@angular/router';
import { ModuleWithProviders, Injectable } from '@angular/core';
export const appRoutes: Routes = [
	{
		path: '',
		loadChildren: () => import('./WorkflowManagement/workflow-management.module').then(m => m.WorkflowModule)
	}
];

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {
		// Optional Step: Do some stuff with the url if needed.
		return super.parse(url);
		// If you lower it in the optional step 
		// you don't need to use "toLowerCase" 
		// when you pass it down to the next function
		//return super.parse(url.toLowerCase());
	}
}

export const WorkflowAppRouting: ModuleWithProviders =
	RouterModule.forRoot(appRoutes);