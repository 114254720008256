import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Injectable, NgZone } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';

const onServerRequest: string = 'onServerRequest';
const loginRequestUrl: string = '/api/Account/Login';
const logoutRequestUrl: string = '/api/Account/Logout';


@Injectable()
export class RequestNotifierService {
	request: Subject<number> = new Subject<number>();
	channel: BroadcastChannel<number> = new BroadcastChannel<number>(onServerRequest);

	constructor(private zone: NgZone) {
		//window.addEventListener("storage", this.onOuterRequest.bind(this));
		this.channel.onmessage = (t) => /*this.zone.run(() =>*/ this.request.next(t)/*)*/;
	}
	//private onOuterRequest(ev: StorageEvent) {
	//	if (ev.key == onServerRequest) {
	//		this.request.next();
	//	}
	//}

	notify(url) {
		if (url != loginRequestUrl && url != logoutRequestUrl) {
			//localStorage.setItem(onServerRequest, Date.now().toString());
			let activityTime = Date.now();
			this.channel.postMessage(activityTime);
			this.request.next(activityTime);
		}
	}

	onServerRequest(): Observable<number> {
		return this.request.asObservable();
	}

}