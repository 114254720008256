import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ErrorType } from '../../Models/Enums';
import { ResponseMessage, ResponseCallback } from '../../Models/reponseMessage';


@Injectable()
export class ErrorNotifierService {
	constructor(private http: HttpClient) { }

	error: Error & HttpErrorResponse;
	errorType: ErrorType;
	url: string;

	private errorDetector: BehaviorSubject<Error> = new BehaviorSubject<Error>(null);

	notifyClientError(error: Error & HttpErrorResponse) {
		this.url = window.location.href;
		this.errorType = ErrorType.Client;
		this.error = error;
		this.errorDetector.next(error);
	}

	notifyServerError(error: HttpErrorResponse) {
		this.url = window.location.href;
		this.errorType = ErrorType.Server;
		this.error = error;
		this.errorDetector.next(error);
	}

	onError(): Observable<Error | HttpErrorResponse> {
		return this.errorDetector.asObservable();
	}

	reportError(callback: ResponseCallback) {
		const exception = this.errorType === ErrorType.Server ? (this.error as HttpErrorResponse).error : null;

		//this.http.post('http://localhost:57280/Error/ReportError', {
		this.http.post('https://services.bio-aware.com/Error/ReportError', {
			Url: this.url,
			ErrorType: this.errorType,
			ErrorTitle: this.error.message,
			ErrorInfo: {
				name: this.error.name,
				message: exception === null ? this.error.message : exception.Message,
				stack: exception === null ? this.error.stack : exception.StackTrace,
			}
		}).subscribe(response => callback(response as ResponseMessage));
	}
}