import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataOfGrid, GridParams, ResponseCallback, ResponseGridCallback, ResponseMessage } from '@shared/Models/reponseMessage';
import { AgGridQuery } from '../models/AgGrid/ag-grid-query.model';
import { TASKS } from '../models/mock-workflow-task';
import { Workflow } from '../models/workflow.model';

@Injectable()
export class WorkflowService {
	constructor(private http: HttpClient) {
	}
	getWorkflowTaks() {
		return Promise.resolve(TASKS);
		//this.http.get('/api/Workflow').subscribe(response => callback(response as DataOfGrid), error => console.log(WorkflowService.name, error));

	}
	getTemplate(workflowId: string, callback: ResponseCallback) {
		this.http.get(`/api/Workflow/template/${workflowId}`).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}
	saveWorkflow(workflow: Workflow,  callback: ResponseCallback) {
		this.http.post('/api/Workflow/template', workflow).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error));
	}
	getTemplateList(query: AgGridQuery, callback: ResponseGridCallback) {
		
		this.http.post('/api/Workflow/templates', query).subscribe(response => callback(response as DataOfGrid), error => console.log(WorkflowService.name, error));
	}
	getInstanceListByTemplate(query: AgGridQuery, callback: ResponseGridCallback) {
		
		this.http.post('/api/Workflow/instances', query).subscribe(response => callback(response as DataOfGrid), error => console.log(WorkflowService.name, error));
	}
	getAllInstanceList(query: AgGridQuery, callback: ResponseGridCallback) {		
		
		this.http.post('/api/Workflow/instances', query).subscribe(response => callback(response as DataOfGrid), error => console.log(WorkflowService.name, error));
	}
	getInstance(workflowId: string, callback: ResponseCallback) {
		this.http.get(`/api/Workflow/instance/${workflowId}`).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}
	getRunningTasks(query: AgGridQuery, callback: ResponseGridCallback) {		
		this.http.post('/api/Workflow/tasks',query).subscribe(response => callback(response as DataOfGrid), error => console.log(WorkflowService.name, error))
	}
	saveTask(taskEntity, callback: ResponseCallback) {
		this.http.post('/api/Workflow/task', taskEntity).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}
	startWorkflowInstance(templateId: string, instanceName: string, callback: ResponseCallback) {
		this.http.post('/api/Workflow/instance', { templateId: templateId, instanceName: instanceName }).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}
	deleteWorkflow(workflowId: string, callback: ResponseCallback) {
		this.http.delete(`/api/Workflow/template/${workflowId}`).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}	
	duplicateWorkflowInstance(templateId: string, instanceName: string, callback: ResponseCallback) {
		this.http.post('/api/Workflow/duplicateinstance', { templateId: templateId, instanceName: instanceName }).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))
	}
	getLinkedRecords(linkedTable: string, linkedField: string, operation: string, linkedFieldValue: string, isTemplate: boolean, callback: ResponseCallback) {
		const params = new HttpParams({
			fromObject: {
				linkedTable: linkedTable,
				linkedField: linkedField,
				linkedFieldValue: linkedFieldValue,
				operation: operation,
				isTemplate: isTemplate.toString()
			}});
		this.http.get('/api/Workflow/link', { params: params }).subscribe(response => callback(response as ResponseMessage), error => console.log(WorkflowService.name, error))

	}
}