import { Injectable } from '@angular/core';
import { Router, NavigationEnd, GuardsCheckEnd, RouterEvent, GuardsCheckStart, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PreviousRouteService {

	private previousUrl: string = '/';
	private previousQueryParams: {};
	private firstVisit: boolean = true;

	public redirected = false;

	public unset: boolean = true;
	public isBackend: boolean = false;

	constructor(router: Router) {
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.isBackend = event.url.startsWith('/admin/data');
			}
			if (event instanceof NavigationEnd || event instanceof GuardsCheckStart) {
				if ((<RouterEvent>event).url != '/account/login' && (<RouterEvent>event).url != '/account/logout') {
					this.unset = true;
					let urlSegments = (<RouterEvent>event).url.split('?');
					this.previousUrl = decodeURI(urlSegments[0]);
					if (urlSegments.length > 1) {
						this.setQueryParams(urlSegments[1]);
					}
				}
			};
			if (event instanceof NavigationEnd && !this.redirected) {
				if (this.firstVisit) {
					this.firstVisit = false;
				}
				else {
					this.redirected = true;
				}
			}
		});
	}

	private setQueryParams(search: string) {
		var search = search.startsWith('?') ? location.search.substring(1) : search;
		return this.previousQueryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
	}

	private setPreviousUrl(includeQueryString: boolean, url: string) {
		this.unset = false;
		delete this.previousQueryParams;
		if (includeQueryString && window.location.search != "") {
			this.setQueryParams(window.location.search);
		}
		return this.previousUrl = url;
	}

	public getPreviousUrl() {
		return this.previousUrl;
	}

	public markAsPreviousUrl(includeQueryString: boolean = true) {
		return this.setPreviousUrl(includeQueryString, window.location.pathname);
	}

	public unmarkAsPreviousUrl() {
		this.previousUrl = '/';
	}

	public markAsPreviousUrlWithParameter(parm: string | number, includeQueryString: boolean = true) {
		return this.setPreviousUrl(includeQueryString, window.location.pathname + '/' + parm);
	}

	public getQueryParams() {
		return this.previousQueryParams;
	}

}