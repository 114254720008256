import { EventEmitter } from '@angular/core';

function isObject(obj) {
	var type = typeof obj;
	return type === 'function' || type === 'object' && !!obj;
};
function isArray(obj) {
	return Array.isArray(obj);
};
export function clone<T>(src: T, ignoreProperties: Array<string> = null) {
	let target :T = <T>{};
	for (let prop in src) {
		if(ignoreProperties != null && ignoreProperties.indexOf(prop) > -1) {
			continue;
		}
		if (src.hasOwnProperty(prop)) {
			// if the value is a nested object, recursively copy all it's properties
			if (isArray(src[prop])) {
				let array = [];
				for (var i = 0; i < (<any>src[prop]).length; i++) {
					array.push(clone((<any>src[prop])[i], ignoreProperties));
				}
				(<any>target[prop]) = array;
			}
			else if ((src[prop] instanceof EventEmitter)) {
				//console.log(src[prop]);
			}
			else if (isObject(src[prop])){
				target[prop] = clone(src[prop], ignoreProperties);
			} else {
				target[prop] = src[prop];
			}
		}
	}
	return target;
}

export function refreshStyles() {
	var newStyle = document.createElement('link');
	newStyle.id = 'styles';
	newStyle.rel = 'stylesheet';
	newStyle.href = '/Content/Custom.css?v=' + Date.now();

	document.getElementById('styles').replaceWith(newStyle);
}

export function isUrl(value: string): boolean {
	return value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/) != null
		|| value.match(/^mailto:(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null
}
export function getValidUrl(url = "") {
	let newUrl = window.decodeURIComponent(url);
	newUrl = newUrl.trim();/*.replace(/\s/g, "")*/

	if (/^(:\/\/)/.test(newUrl)) {
		return `http${newUrl}`;
	}
	if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
		return `http://${newUrl}`;
	}

	return newUrl;
};
export function dispatchResize(composed: boolean = false) {
	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
		var evt : any = document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	} else {
		window.dispatchEvent(new Event('resize', { composed: composed }));
	}
}

export function endAppSpinner() {
	const appSpinner = document.getElementById("app-spinner");
	if (appSpinner) {
		appSpinner.parentNode.removeChild(appSpinner);
	}
}

export function hideAppSpinner() {
	const appSpinner = document.getElementById("app-spinner");
	appSpinner.style.display = 'none';
}

export function showAppSpinner() {
	const appSpinner = document.getElementById("app-spinner");
	appSpinner.style.display = 'block';
}

export function displayError(title: string, description: string, info: string = null, withContinue: () => void = null) {
	document.body.innerHTML = `
						<div id="error" class="notfound row fullscreen">
							<div class="notfound-404"></div>
								<div class="col">
									<!-- Title -->
									<p class="mat-title mat-error">${title}</p>
									<!-- Description -->
									<p class="mat-hint">${description}</p>
									${info != null ? '<p class="mat-hint">' + info + '</p>' : ''}
									${withContinue != null ? '<a id="continue" href="javascript:void(0)">Continue</a>' : ''}
									
								</div>
						</div>` + document.body.innerHTML;
	if(withContinue) {
		document.getElementById("continue").addEventListener("click", function() {
			document.body.removeChild(document.getElementById("error"));
			withContinue();
		});
	}	
}
export function isNullOrEmpty(val) {
	return (val === undefined || val == null || val.length <= 0) ? true : false;
}
export function truncateText(val, length, ellipsis = false) {
	if (isNullOrEmpty(val)) {
		return '';
	}
	return (ellipsis && val.length > length) ? val?.substring(0, Math.min(length, val?.length)) + '...' : val?.substring(0, Math.min(length, val?.length))
}