import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
	selector: 'app',
	template: `<router-outlet></router-outlet>`,
})
export class WorkflowAppComponent {	

	constructor(private router: Router) {

	}	

}

