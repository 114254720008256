import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private configUrl = 'config.json';
	private config : Config;
	constructor(private http: HttpClient) { }
	loadConfig() {
		return this.http.get<Config>(this.configUrl)
			.toPromise()
			.then((data: Config) => {
				this.config = data;
		});
	}
	getConfig(): Config{
		if (!this.config) {
			throw Error('Config file not loaded!');
		}
		return this.config;
	}
}
export interface Config {
	baseUrl: string;
	websiteId: number;
	encryptionKey: number;
	encryptionIv: number;
	scripts: Array<Script>;
	googleReCaptcha: GoogleReCaptcha;
}

export interface GoogleReCaptcha {
	clientKey: string;
}

export interface Script {
	name: string;
	src: string;
	global: boolean;
}