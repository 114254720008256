import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { ResponseCallback, ResponseMessage } from '../../shared/Models/reponseMessage';
import { Saver, SAVER } from '../providers/file-saver.provider';

export interface Download {
	state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
	progress: number;
	content: Blob | null;
}
function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
	return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
	return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}
export function downloadProgress(
	saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
	return (source: Observable<HttpEvent<Blob>>) =>
		source.pipe(
			scan((previous: Download, event: HttpEvent<Blob>): Download => {
				if (isHttpProgressEvent(event)) {
					return {
						progress: event.total
							? Math.round((100 * event.loaded) / event.total)
							: previous.progress,
						state: 'IN_PROGRESS',
						content: null
					}
				}
				if (isHttpResponse(event)) {
					if (saver && event.body) {
						saver(event.body)
					}
					return {
						progress: 100,
						state: 'DONE',
						content: event.body
					}
				}
				return previous
			},
				{ state: 'PENDING', progress: 0, content: null }
			)
		)
}

@Injectable()
export class FileService {
	constructor(private http: HttpClient, @Inject(SAVER) private save: Saver) {
	}
	uploadFile(file, workflowId) {
		const formData: FormData = new FormData();
		formData.append('file', file, file.name);
		formData.append('workflowId', workflowId);

		return this.http.post('/api/file/uploadfile', formData, {
			reportProgress: true,
			observe: 'events'
		});
	}
	public downloadFile(taskId: string, filename: string): Observable<Download> {
		const params = new HttpParams().set("taskId",taskId);
		return this.http.get('/api/file/downloadfile', {
			reportProgress: true,
			observe: 'events',
			responseType: 'blob',
			params: params
		}).pipe(downloadProgress(blob => this.save(blob, filename)))
			//.subscribe(response => callback(response), error => console.log(FileService.name, error));
	}
}
